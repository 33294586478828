<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Texosmotr hujjati ma'lumotlari</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row v-if="!isLoading">
              <v-col cols="4" class="pl-0">
                <v-text-field
                  :value="details.truck_info.truck_number"
                  label="Mashina raqami"
                  disabled
                  dense
                  :loading="isLoading"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-text-field
                  :value="
                    (details.truck_info.status = 'PUS' ? 'Bo`sh' : 'Bosh emas')
                  "
                  label="Status"
                  disabled
                  dense
                  :loading="isLoading"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-text-field
                  :value="
                    details.truck_info.is_normal_truck_documents
                      ? 'Norma'
                      : 'Kamchilik bor'
                  "
                  label="Hujjatlari"
                  disabled
                  :loading="isLoading"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-text-field
                  :value="details.monitored | dateFilter"
                  label="Tekshirilgan sana"
                  disabled
                  :loading="isLoading"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-text-field
                  :value="details.reg_number"
                  label="Reg raqami"
                  disabled
                  :loading="isLoading"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-text-field
                  :value="details.monitored_by"
                  label="Tekshirgan organ hodimi"
                  disabled
                  :loading="isLoading"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-text-field
                  :value="details.expires | dateFilter"
                  label="Tugash sanasi"
                  disabled
                  :loading="isLoading"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {}
  },
  beforeCreate() {
    this.$store.dispatch('getTechMonitorDetails', this.$route.params.id)
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    details() {
      const data = this.$store.state.requests.techMonitorDetails
      return data
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Texosmotr hujjati ma'lumotlari" }
    ])
  },
  methods: {}
}
</script>
